<template>
    <div class="container-box">
        <div class="top-wrap">
            <div class="formItem">
                <p class="title"><b>信息填写</b></p>
                <div class="itemWrap">
                    <div class="listItem">
                        <div class="item iTitle">本次报名人数</div>
                        <div class="item"><Input @monitor="getMonitor" type="enrollment" /></div>
                        <!-- <div class="item">
							 <el-input v-model="text" placeholder="请输入" />
						</div> -->
                        <div class="item iTitle">本次报名组数</div>
                        <div class="item"><Input @monitor="getMonitor" type="numberGroups" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">竞赛单人费用</div>
                        <div class="item"><Input @monitor="getMonitor" type="unitPrice"
                                :payment_unit_price="payment_unit_price" /></div>
                        <div class="item iTitle">本次上传应缴费</div>
                        <div class="item"><Input @monitor="getMonitor" type="totalPrice" :totalNum="totalNum" /></div>
                    </div>
                    <div class="listItem" style="border-bottom:none">
                        <div class="item iTitle">备注信息</div>
                        <div class="item" style="border-bottom:none">
                            <Input @monitor="getMonitor" type="invoiceNotes" />
                        </div>
                        <div class="item iTitle"></div>
                        <div class="item"></div>
                        <!-- <div class="item iTitle">发票内容</div>
                        <div class="item" style="border-bottom:none"><Input @monitor="getMonitor" type="invoiceText" />
                        </div> -->
                        <!-- <div class="item iTitle">发票抬头</div>
                        <div class="item"><Input @monitor="getMonitor" type="invoiceHead" /></div> -->
                    </div>
                    <div class="listItem">
                        <!-- <div class="item iTitle">税号</div>
                        <div class="item">
                            <Input @monitor="getMonitor" type="taxNumber" />
                        </div> -->

                    </div>
                    <div class="listItem">
                        <!-- <div class="item iTitle">纸质发票/电子发票</div> -->
                        <!-- <div class="item iTitle">电子发票接收邮箱</div>
                        <div class="item" style="border-bottom: 1px solid #ecedf0;"><Input ref="emailInput"
                                @monitor="getMonitor" type="email" /></div>
                        <div class="item iTitle"></div> -->
                        <!-- <div class="item" style="border-bottom: 1px solid #ecedf0;">
                            <Input @monitor="getMonitor" type="fp3" />
                        </div> -->
                    </div>
                    <!-- <div class="lastItem">
                        <div class="item iTitle">纸质发票邮寄地址</div>
                        <div class="item"><Input @monitor="getMonitor" type="qt1" /></div>
                        <div class="item iTitle"></div>
                        <div class="item"></div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="bottom-wrap">
            <div class="left-wrap">
                <div class="formItem">
                    <!-- 支付方式 -->
                    <p class="title "><b></b></p>
                    <div class="upload">
                        <el-upload ref="upload" class="avatar-uploader" action="" :show-file-list="false"
                            :on-remove="handleRemove" :on-change="onChange" :on-success="handleUploadSuccess"
                            :before-upload="beforeAvatarUpload" :http-request="httpRequest" :file-list="fileList">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>
                    </div>
                    <span class="upload-title">点击上传支付凭证</span>
                </div>

                <div class="payment">
                    <p class="payMode">报名支付方式</p>
                    <p class="typeface font_style">方式一(转账支付):</p>
                    <p class="typeface">收费账户:</p>
                    <p class="typeface">得时（天津）教育科技有限公司</p>
                    <p class="typeface">开户银行:</p>
                    <p class="typeface">招商银行股份有限公司天津高新区支行</p>
                    <p class="typeface">转账账号:</p>
                    <p class="typeface">122918265810000</p>
                    <p class="typeface typefaceStyle">转账务必请备注: 学校+人数</p>
                    <!-- <p><el-link type="primary" @click="Download">大唐杯参赛委托代收证明下载</el-link></p> -->

                </div>
                <div class="vertical-line"></div>
            </div>
            <!-- <div class="right-wrap" v-if="openInvoicePaymentInfo">
                <img src="../../assets/images/wchat_payment.png">
                <p>扫描二维码进行支付</p>
            </div> -->
            <div class="betten-wrap">
                <div class="payment">
                    <p class="typeface font_style" style="margin-top: .59rem;">方式二(微信扫码支付见右图):</p>
                    <p class="typeface">收费账户:</p>
                    <p class="typeface">得时（天津）教育科技有限公司</p>
                    <p class="typeface">支持公务卡/信用卡扫码支付</p>
                    <p class="typeface typefaceStyle">转账务必请备注: 学校+人数</p>
                    <p style="margin-top: 30px;font-size: 14px;"><el-link type="primary" @click="Download">大唐杯参赛委托代收证明下载</el-link></p>
                </div>
            </div>
            <div class="right-wrap" style="margin-top:1rem">
                <div style="font-size:0.2rem;text-align:center;color:red">暂缓开通</div>
                <div style="font-size:0.2rem;text-align:center;color:red">预计本月底开放二维码支付</div>
            </div>
            <div class="right-wrap" v-if="false">
                <img src="../../assets/images/boyaweixin.jpg">
                <p>扫描二维码进行支付</p>
            </div>
            <div class="center-wrap">
                <el-button v-loading="loading" @click="save">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Input from './textInput.vue';
import { Plus } from '@element-plus/icons-vue';
import { httpRequest } from '@/tools/common.js'
import { userInfo } from "api/apis.js";
import { ElMessage } from 'element-plus'
import { Debounce } from '@/tools/common.js'
export default {
    name: 'Step',
    components: {
        Input,
        Plus
    },
    data() {
        return {
            text: '',
            imageUrl: '',
            dialogImageUrl: '',
            dialogVisible: true,
            boyaNewStatePaymentInfo: false,
            tjPaymentInfo: false,
            openInvoicePaymentInfo: false,
            info: true,
            time: new Date(),
            fileList: [],
            groupList: [],
            // url: '',
            pay_pic_path: '',
            formItem: {
                number: '', //报名人数
                // groupNum: '',
                sFeeNum: '',
                money: '', //应缴费
                team_num: '',
                invoice_content: '',//发票类型
                invoice_title: '', //发票抬头
                invoiceHeader: '',
                invoice_num: '',//税号
                invoice_way: '',
                memo: '',//备注
                invoice4: '',
                invoice_address: '',//发票邮寄地址
                other2: '',
                invoiceType: true
            },
            is_need_bill: '',
            loading: false,
            payment_unit_price: 120,
            totalNum: 0,//应缴费
            options: [
                {
                    value: 'Option1',
                    label: '微信',
                },
                {
                    value: 'Option2',
                    label: '支付宝',
                },
            ],
            query: {},
            paymentType: '',
        }
    },
    created() {
        //获取单人价格
        this.InitPrice();
        this.query = this.$route.query;
        const { total_Obj } = this.$route.query;
        const { team_nums, nums } = JSON.parse(total_Obj);
        this.totalNum = Number(nums) * Number(this.payment_unit_price);
        this.formItem.money = this.totalNum;
        this.formItem.team_num = team_nums;
        this.formItem.number = nums;
        this.getPaymentInfo();
    },
    methods: {
        InitPrice() {
            userInfo.price().then(res => {
                this.payment_unit_price = res.payment_unit_price
            })
        },
        //获取回显信息
        getMonitor(val, type, isFormat) {
            if (type == 'enrollment') { //报名人数
                this.formItem.number = `${val}`;
            } else if (type == 'numberGroups') { //组数
                this.formItem.team_num = `${val}`;
            } else if (type == 'unitPrice') { //缴费单价
                this.formItem.sFeeNum = `${val}`;
            } else if (type == 'totalPrice') { //缴费总价
                this.formItem.money = `${val}`;
            } else if (type == 'invoiceText') { //发票内容
                if (!val) {
                    this.boyaNewStatePaymentInfo = false;
                    this.openInvoicePaymentInfo = false;
                    this.tjPaymentInfo = false;
                    this.info = true
                }
                else {
                    this.boyaNewStatePaymentInfo = false;
                    this.tjPaymentInfo = false;
                    this.openInvoicePaymentInfo = true;
                    this.info = false
                    this.is_need_bill = '是';
                    if (`${val}` == '现代服务*报名费') {
                        let groups = ["中职", "高职"];
                        this.boyaNewStatePaymentInfo = false;
                        this.openInvoicePaymentInfo = false;
                        this.tjPaymentInfo = false;
                        const { engineeringPractice, innovation } = this.$route.query;
                        let paractice = JSON.parse(engineeringPractice);
                        let innovationInfo = innovation;
                        let groups_enginnering = [];
                        paractice.forEach(element => {
                            groups_enginnering.push(element.group)

                        });
                        let groups_innovation = []
                        innovationInfo.forEach(element => {
                            groups_innovation.push(element.group)

                        });
                        const diffSet = groups_enginnering.concat(groups_innovation).filter(item => !groups.includes(item));

                        if (diffSet.length > 0 && groups_innovation.length == 0) {
                            this.tjPaymentInfo = true;
                        }
                        else {
                            this.boyaNewStatePaymentInfo = true;
                        }
                    }
                    else if (`${val}` == '不开发票') {
                        this.is_need_bill = '否';
                    }


                    this.formItem.invoice_content = `${val}`;
                }

            }
            else if (type == 'invoiceHead') { //发票抬头
                this.formItem.invoice_title = `${val}`;
            }
            else if (type == 'taxNumber') { //税号
                this.formItem.invoice_num = `${val}`;
            }
            else if (type == 'invoiceNotes') { //发票备注栏信息
                this.formItem.memo = `${val}`;
            }
            //电子邮箱
            else if (type == 'email') { //邮箱
                this.formItem.invoice_address = `${val}`;
                sessionStorage.setItem('FP4', `${val}`);
            }


        },
        //提交缴费
        save: Debounce(function () {
            if (this.pay_pic_path == '') {
                ElMessage({
                    message: "请上传支付凭证",
                    type: "error",
                });
                return
            }
            let team_ids = this.query.team_ids
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            if (this.formItem.invoice_content == '不开发票') {
                this.formItem.invoice_num = ''
                this.formItem.memo = ''
                this.formItem.invoice_address = ''
                this.formItem.invoice_way = ''
                this.formItem.invoice_title = ''
            }
            dataobj.append("user_id", uid);//用户id
            dataobj.append("number", this.formItem.number);//报名人数
            dataobj.append("team_num", this.formItem.team_num);//组数
            dataobj.append("money", this.formItem.money);//费用
            dataobj.append("is_need_bill", this.is_need_bill);//
            dataobj.append("invoice_title", this.formItem.invoice_title);//发票抬头
            dataobj.append("invoice_num", this.formItem.invoice_num);//税号
            dataobj.append("invoice_content", this.formItem.invoice_content);
            dataobj.append("invoice_way", '电子发票');
            dataobj.append("memo", this.formItem.memo);
            dataobj.append("invoice_address", this.formItem.invoice_address);
            dataobj.append("pay_pic_path", this.pay_pic_path); //图片路径
            dataobj.append("team_ids", JSON.stringify(team_ids)); //团队id
            dataobj.append("company_info",this.paymentType ); //团队id
            this.loading = true;
            userInfo.invoice(dataobj).then(res => {
                this.loading = false;
                if (res.code === 200) {
                    ElMessage({
                        message: "提交成功",
                        type: "success",
                    });
                    this.$router.push('/Master');
                } else {
                    ElMessage({
                        message: res.msg,
                        type: "success",
                    });

                    this.$router.push('/Master');
                }
            })
        }, 1000),
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        // 上传的方法
        onChange(file, fileList) {
            this.fileList = fileList
        },
        handleUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList, 'response, file, fileList')
        },
        handleRemove(e, fileList) {
            this.fileList = fileList;
        },
        beforeAvatarUpload(file) {
            // 文件大小校验
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                this.$message.error('文件大小不能超过 10M !');
            }
            return isLt2M;
        },

        //回调接收上传成功的参数
        getHttpRelut(res) {
            //业务逻辑
            this.imageUrl = res.url;
            this.pay_pic_path = res.url;
        },
        //上传时的oss 
        // user_register// 用户注册
        // sign_up_audit// 审核 
        httpRequest(file) {
            httpRequest(file, "sign_up_audit", this.getHttpRelut);
        },
        // 文件下载
        Download() {
            window.open('./static/ZhenM/第十二届大唐杯参赛费代收证明.pdf');
        },

        //缴费公司信息查询
        getPaymentInfo(type) {
            let object = this.$route.query;
            let group_li;
            let track_li;
            let competition_type_li;
            // let competition_type_li = JSON.stringify([object.competition_type_li]);
            // let track_li = JSON.stringify([object.track_li]);

            if (typeof object.track_li === "string") {
                track_li = JSON.stringify([object.track_li]);
            } else {
                track_li = JSON.stringify(object.track_li);
            }

            if (typeof object.track_li === "string") {
                competition_type_li = JSON.stringify([object.competition_type_li]);
            } else {
                competition_type_li = JSON.stringify(object.competition_type_li);
            }

            if (typeof object.group_li === "string") {
                group_li = JSON.stringify([object.group_li]);
            } else {
                group_li = JSON.stringify(object.group_li);
            }
            let dataobj = new FormData();
            dataobj.append("competition_type_li", competition_type_li);
            dataobj.append("track_li", track_li);
            dataobj.append("group_li", group_li);
            userInfo.paymentInfo(dataobj).then((res) => {
                if (res.code === 200) {
                    this.paymentType = JSON.parse(res.data) || '';
                    console.log(this.paymentType)
                }
            })
        }

    },
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    }
}
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 3.14rem;
    height: 3rem;
    display: block;
}
</style>
<style lang="scss" scoped>
:deep(.el-select .el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
    border: none;

    .el-input__inner {}
}

:deep(.el-textarea__inner) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    resize: none;
}


:deep(.el-upload) {
    width: 2.14rem !important;
    height: 2.83rem !important;
}

:deep(.el-select) {
    margin-bottom: 0.2rem;

}

.payment :deep(.el-input__wrapper) {
    background-color: #F5F7FA;
    -webkit-box-shadow: 0px 0.06rem 3px #c8c8c8;

    -moz-box-shadow: 0px 0.06rem 3px #c8c8c8;

    box-shadow: 0px 0.06rem 3px #c8c8c8;

}

:deep(.el-button) {
    width: 1.71rem;
    height: 0.45rem;
    background-color: #436E8A;
    color: white;
    font-size: 0.24rem;
}

.avatar-uploader {
    border: 1px dashed #dcdfe6;
}

.avatar-uploader:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload {
    border: 1px dashed #dcdfe6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

@import './index.scss';
</style>